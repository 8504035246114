<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<LimitProductForm
		v-else
		is-edit-mode
		:is-loading="isLoading"
		:is-updating="isUpdating"
		:data="edit"
		@onSubmit="handleSubmit"
	/>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import LimitProductForm from '@/components/LimitProductForm.vue';
import { scrollToTop } from '../../assets/js/helpers';

export default {
	name: 'LimitProductEdit',
	components: {
		LimitProductForm,
	},
	data() {
		return {
			id: Number(this.$route.params.id) || null,
		};
	},
	computed: {
		...mapState('limitProduct', {
			detail: 'detail',
			update: 'update',
		}),
		isLoading() {
			return this.detail.isLoading;
		},
		isUpdating() {
			return this.update.isLoading;
		},
		edit() {
			return this.detail.data;
		},
	},
	watch: {
		data(value) {
			this.setPageTitle({
				title: value.name,
				isShow: true,
			});
		},
	},
	async created() {
		await this.getLimitProduct({ id: this.id });
		await this.setPageTitle({
			title: this.edit.name,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			getLimitProduct: 'limitProduct/getLimitProduct',
			updateLimitProduct: 'limitProduct/updateLimitProduct',
		}),
		async handleSubmit(payload) {
			try {
				await this.updateLimitProduct({ id: this.id, payload });
			} finally {
				scrollToTop();
			}
		},
	},
};
</script>
